/**
 * mezzofy优惠券数据
 */
export const MEZZOFY_COUPON = {
  "voucherId": "777495399",
  "voucherCode": "77749699",
  "voucherDisplayCode": "CD:9Tbqx7owLp3O7JEEEYXuXG3T92cjuAxXYuJcQkCQBzg=",
  "voucherName": "現金券$10_MezzofyTestData",
  "voucherType": 1,
  "voucherStatus": 1,
  "voucherDiscount": 0,
  "voucherAmount": 1000,
  "minUsage": 0,
  "voucherProductCode": [],
  "minUsageProductCode": [],
  "voucherCategory": [],
  "voucherCategoryCode": [],
  "pointsoftProducts": [
    "77749531"
  ],
  "pointsoftExclProducts": [],
  "canDaoProductType": 0,
  "canDaoExclProductType": 0,
  "canDaoFixPrice": 0,
  "canDaoPriceType": 0,
  "voucherCalType": 0,
  "includedBoxedMealFee": false,
}