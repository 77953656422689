/**
 * 默认券规则
 * @type {[{haveThreshold: boolean, voucherType: number, availableVoucherType: [{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number},null,null]},{haveThreshold: boolean, voucherType: number, availableVoucherType: [{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number}]},{haveThreshold: boolean, voucherType: number, availableVoucherType: [{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number},null,null]},{haveThreshold: boolean, voucherType: number, availableVoucherType: [{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number}]},{haveThreshold: boolean, voucherType: number, availableVoucherType: [{haveThreshold: boolean, voucherType: number},{haveThreshold: boolean, voucherType: number}]},null,null]}
 */
export const COUPON_RULES = [
  // 现金券
  {
    voucherType: 1,
    haveThreshold: false,
    availableVoucherType: [
      {
        voucherType: 1,
        haveThreshold: false
      },
      {
        voucherType: 2,
        haveThreshold: false
      },
      {
        voucherType: 3,
        haveThreshold: false
      },
      {
        voucherType: 3,
        haveThreshold: true
      },
      {
        voucherType: 4,
        haveThreshold: false
      },
      {
        voucherType: 5,
        haveThreshold: false
      },
      {
        voucherType: 5,
        haveThreshold: true
      }
    ]
  },
  // 折扣券
  {
    voucherType: 2,
    haveThreshold: false,
    availableVoucherType: [
      {
        voucherType: 1,
        haveThreshold: false
      },
      {
        voucherType: 3,
        haveThreshold: false
      }
    ]
  },
  // 赠品券 - 无门槛
  {
    voucherType: 3,
    haveThreshold: false,
    availableVoucherType: [
      {
        voucherType: 1,
        haveThreshold: false
      },
      {
        voucherType: 2,
        haveThreshold: false
      },
      {
        voucherType: 3,
        haveThreshold: false
      },
      {
        voucherType: 3,
        haveThreshold: true
      },
      {
        voucherType: 4,
        haveThreshold: false
      },
      {
        voucherType: 5,
        haveThreshold: false
      },
      {
        voucherType: 5,
        haveThreshold: true
      }
    ]
  },
  // 赠品券 - 有门槛
  {
    voucherType: 3,
    haveThreshold: true,
    availableVoucherType: [
      {
        voucherType: 1,
        haveThreshold: false
      },
      {
        voucherType: 3,
        haveThreshold: false
      }
    ]
  },
  // 满减券
  {
    voucherType: 4,
    haveThreshold: false,
    availableVoucherType: [
      {
        voucherType: 1,
        haveThreshold: false
      },
      {
        voucherType: 3,
        haveThreshold: false
      }
    ]
  },
  // 一口价券 - 无门槛
  {
    voucherType: 5,
    haveThreshold: false,
    availableVoucherType: [
      {
        voucherType: 1,
        haveThreshold: false
      },
      {
        voucherType: 3,
        haveThreshold: false
      },
      {
        voucherType: 5,
        haveThreshold: false,
      }
    ]
  },
  // 一口价券 - 有门槛
  {
    voucherType: 5,
    haveThreshold: true,
    availableVoucherType: [
      {
        voucherType: 1,
        haveThreshold: false
      },
      {
        voucherType: 3,
        haveThreshold: false
      }
    ]
  },
]
