export function copyData(source) {// 深拷貝
    var result = {};
    if (Array.isArray(source)) {
        result = [];
    }
    for (var key in source) {
        if (Array.isArray(source[key])) {
            result[key] = copyData(source[key])
        } else {
            result[key] = typeof source[key] === "object" ? copyData(source[key]) : source[key];
        }
    }
    return result;
}