<template>
  <div>
    <div class="tabbar">
      <div class="main" @click="selectPage(1)">
        <template v-if="!loading && (themeGetConfig?.menu_order?.url || themeGetConfig?.menu_order_s?.url)">
          <img v-show="page != 1" :src="themeGetConfig.menu_order.url" alt="" />
          <img v-show="page == 1" :src="themeGetConfig.menu_order_s.url" alt="" />
        </template>
        <template v-else>
          <div class="main-item SkeletonItem"></div>
        </template>
        <p>{{themeGetConfig?.menu_order?.label}}</p>
      </div>
      <div class="coupon" :class="{yuuActive:isYuu}" @click="selectPage(2)">
        <template v-if="!loading && (themeGetConfig?.menu_coupon?.url || themeGetConfig?.menu_coupon_s?.url)">
          <img v-show="page != 2" :src="themeGetConfig?.menu_coupon?.url" alt="" />
          <img v-show="page == 2" :src="themeGetConfig?.menu_coupon_s?.url" alt="" />
        </template>
        <template v-else>
          <div class="main-item SkeletonItem"></div>
        </template>
        <p>{{themeGetConfig?.menu_coupon?.label}}</p>
      </div>
      <div class="order" :class="{yuuActive:isYuu}" @click="selectPage(3)">
        <template v-if="!loading && (themeGetConfig?.menu_history?.url || themeGetConfig?.menu_history_s?.url)">
          <img v-show="page != 3" :src="themeGetConfig?.menu_history?.url" alt="" />
          <img v-show="page == 3" :src="themeGetConfig?.menu_history_s?.url" alt="" />
        </template>
        <template v-else>
          <div class="main-item SkeletonItem"></div>
        </template>
        <p>{{themeGetConfig?.menu_history?.label}}</p>
      </div>
      <div class="mine" @click="selectPage(4)">
        <template v-if="!loading && (themeGetConfig?.menu_my?.url || themeGetConfig?.menu_my_s?.url)">
          <img v-show="page != 4" :src="themeGetConfig?.menu_my?.url" alt="" />
          <img v-show="page == 4" :src="themeGetConfig?.menu_my_s?.url" alt="" />
        </template>
        <template v-else>
          <div class="main-item SkeletonItem"></div>
        </template>
        <p>{{themeGetConfig?.menu_my?.label}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import language from "../assets/js/language.js";

export default {
  name: "Tabbar",
  props:{
    loading:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // page: this.$store.state.pageTypeNum,
      main: "",
      coupon: "",
      order: "",
      mine: "",
      lanType: this.$store.state.language,
    };
  },
  computed: {
    ...mapState(["isYuu","themeGetConfig"]),
    page() {
      return this.$store.state.pageTypeNum;
    },
  },
  methods: {
    selectPage(page) {
      var path = "home";
      if (page == 1) {
        path = "home";
      } else if (page == 2) {
        path = "coupon/1";
      } else if (page == 3) {
		if (this.isYuu) {
			return;
		}
		if (this.$store.state.userInfo) {
			path = "orderList";
		}else{
      let tipText = this.$store.state.language == 2 ?'Not a Member Yet?':'未登記成為會員?';
      this.$router.push({path:"/login",query:{page:1}})
      this.$toast({message: tipText})
			return
		}
      } else if (page == 4) {
        path = "mine";
      }
      if (this.isYuu) {
        if (page == 2 || page == 3) return;
      }
      this.$store.commit("changeTabPage", page);
      try{
        let view = page == 1 ? 'order_now' : page == 2 ? 'view_coupon' : page == 3 ? 'view_all_deals' : 'view_my_account';
        let pageName = this.$route.name;
        if(page==1||page == 3||page == 2){
          pageName = 'Account'
        }
        window.dataLayer.push({
          event: view,
          page_URL: window.location.href,
          page_title: pageName,
          page_category: pageName +'-page',
        });
      }catch(err){
        console.log(err);
      }
      // this.page = page;
      this.$router.push("/" + path);
      // this.dataLayerPush(page);
    },
    // 埋点
    dataLayerPush(page) {
      var event;
      if (page == 1) {
        event = "product_info";
      } else if (page == 2) {
        event = "coupon_info";
      } else if (page == 3) {
        event = "reorder_page";
      } else if (page == 4) {
        event = "member_center";
      }
      window.dataLayer.push({
        event,
        page_category: "homepage",
      });
    },
    setLanguage() {
      var languageType;
      if (this.$store.state.language == 1) {
        languageType = "chinese";
      } else {
        languageType = "english";
      }
      this.main = language.main[languageType];
      this.coupon = language.coupon[languageType];
      this.order = language.order[languageType];
      this.mine = language.mine[languageType];
    },
  },
  created() {
    this.setLanguage();
  },
};
</script>
<style scoped>
.tabbar {
  width: 3.75rem;
  height: 0.46rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* position: fixed; */
  /* bottom: 0; */
  padding-bottom: 0.1rem;
  border-top: 0.01rem solid #ddd;
  z-index: 999999999999999999999;
  background-color: #fff;
}
.tabbar img {
  width: 0.26rem;
  height: 0.26rem;
  object-fit: cover;
}
.tabbar div {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tabbar p {
  height: 0.14rem;
  font-size: 0.1rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.14rem;
  color: #02050c;
  margin-top: 0.02rem;
}
.yuuActive{
  opacity: 0.5 !important;
}
.main-item{
  width: 0.26rem;
  height: 0.26rem;
  background-color: #e6e7e9;
  border-radius: .04rem;
}
</style>