/**
 * 20221027
 * cris
 * 前端異常監聽
 */

import {saveLogger} from '../assets/utils/request'

let isDev = false
try {
  isDev = process?.env?.NODE_ENV === 'development'
  // console.log('NODE_ENV:' + process.env.NODE_ENV, isDev)
} catch (e) {
  console.log(e)
  isDev = false
}

export const setLogger = (body, level = 'error') => {
  try {
    if (isDev) {
      saveLogger(body, level)
    }
  } catch (e) {
    console.log(e)
  }
}

/**
 * 代碼異常監聽
 * @param message
 * @param source
 * @param lineno
 * @param colno
 * @param error
 */
window.addEventListener('error', event => {
  try {
    const {message} = event
    const stack =  event?.error?.stack || ''
    const body = {
      message,
      listener: 'error',
      stack
    }
    // 屏蔽gtm 异常
    if (stack.indexOf('www.googletagmanager.com/gtm.js')) {
      body.listener = 'info'
      body.sign = 'JRG gtm error'
      body.stack = event?.error?.stack

      setTimeout(() => {
        setLogger(body, 'info')
      }, 2000)
      return
    }
    setLogger(body)
  } catch (err) {
    console.error(err)
  }
}, false)

/**
 * Promise 異常（没有声明catch）
 * @param message
 * @param source
 * @param lineno
 * @param colno
 * @param error
 */
window.addEventListener('unhandledrejection', event => {
  try {
    const reason = event?.reason?.toString() || ''
    const body = {
      message: reason,
      detail: event?.reason?.stack?.toString() || '',
      listener: 'unhandledrejection'
    }
    // 屏蔽 sentry 上报 【SDK 已移除，但上报日志中，仍看到了SDK】
    const regSentry = RegExp(/sentry/)
    if (regSentry.test(body.detail) || regSentry.test(reason)){
      return
    }
    if (reason && reason.indexOf('timeout of') < 0 ) {
      setLogger(body)
    }
  } catch (err) {
    console.error(err)
  }

}, false)
