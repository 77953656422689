/**
 * 20230128 时区转换
 * Cris
 * 时区列表参考：https://jp.cybozu.help/general/zh/admin/list_systemadmin/list_localization/timezone.html
 */


import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const DEFAULT_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss'
const DEFAULT_TIME_ZONE = 'Asia/Shanghai'

/**
 * 获取当前时区
 * @returns {string}
 */
const getTimeZone = () => {
  return dayjs.tz.guess()
}


/**
 * 时间戳转换成某时区时间
 * @param timestamp   时间戳 默认： 设备当前时间的时间戳
 * @param timezone    时区 默认： 东八区
 * @param timeFormat  时间格式 默认格式：YYYY-MM-DD HH:mm:ss
 * @returns {string}
 */
const getFormatDateForTimeZone = (timestamp = '', timezone = DEFAULT_TIME_ZONE, timeFormat = DEFAULT_TIME_FORMAT) => {
  if (!timestamp) {
    timestamp = new Date().getTime()
  }
  const date = dayjs(Number(timestamp))

  return dayjs.tz(date, timezone).format(timeFormat)
}


/**
 * 时间戳转换成某时区时间 信息
 * @param timestamp
 * @param timezone
 * @param timeFormat
 * @returns {{month: number, hour: number, year: number, day: number, minute: number, second: number}}
 */
const getDateByTimestamp = (timestamp = '', timezone = DEFAULT_TIME_ZONE, timeFormat = DEFAULT_TIME_FORMAT) => {
  const date = getFormatDateForTimeZone(timestamp, timezone, timeFormat)

  return {
    year: dayjs(date).year(),
    month: dayjs(date).month() + 1,
    day: dayjs(date).date(),
    hour: dayjs(date).hour(),
    minute: dayjs(date).minute(),
    second: dayjs(date).second(),
    week: dayjs(date).day()
  }
}

/**
 *
 * @param date 日期
 * @param timezone
 * @returns {number}
 */
const getTimestampByDate = (date = '', timezone = DEFAULT_TIME_ZONE) => {
  if (!date) {
    return 0
  }

  return dayjs.tz(date, timezone).valueOf()
}

const getDateForTimeZone = (date = '', timezone = DEFAULT_TIME_ZONE) => {
  if (!date) {
    return ''
  }
  const timestamp = dayjs.tz(date, timezone).valueOf()
  return getDateByTimestamp(timestamp, timezone)
}


export {
  getTimeZone,
  getFormatDateForTimeZone,
  getDateByTimestamp,
  getTimestampByDate,
  getDateForTimeZone
}
// let { year, month, day } = getDateByTimestamp(this.SERVER_TIME)
// nowTime = getTimestampByDate(this.second)
// getDateByTimestamp(this.SERVER_TIME).week

// let bookTime = getTimestampByDate(this.second)
// let start = getTimestampByDate(date + " " + time[0]);
// let end = getTimestampByDate(date + " " + time[1]);

// this.SERVER_TIME

// let nowTime = this.pageParam.book == 2 ? getTimestampByDate(this.second) : this.SERVER_TIME
