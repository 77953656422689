import {createRouter, createWebHistory} from 'vue-router'
import store from '../store'

const Home = () => import(/* webpackChunkName:"main" */ '../views/Home')
const Login = () => import(/* webpackChunkName:"login" */ '../views/login/Login')
const Register = () => import(/* webpackChunkName:"register" */ '../views/register/Register')
const Coupon = () => import(/* webpackChunkName:"coupon" */ '../views/Coupon')
const OrderList = () => import(/* webpackChunkName:"orderList" */ "../views/OrderList")
const Mine = () => import(/* webpackChunkName:"mine" */"../views/Mine")
const PersonInfo = () => import(/*webpackChunkName:"personInfo"*/"../views/mine/PersonInfo")
const EditPas = () => import(/*webpackChunkName:"editpas"*/"../views/mine/EditPas")
const VipCode = () => import(/*webpackChunkName:"vipcode"*/"../views/mine/VipCode")
const Score = () => import(/*webpackChunkName:"score"*/"../views/mine/Score")
const Agreement = () => import(/*webpackChunkName:"agreement"*/"../views/mine/Agreement")
const Privacy = () => import(/*webpackChunkName:"agreement"*/"../views/mine/Privacy")
const Place = () => import(/*webpackChunkName:"Place"*/"../views/mine/Place")
const SendPlace = () => import(/*webpackChunkName:"sendPlace"*/"../views/mine/SendPlace")
const CreditCard = () => import(/*webpackChunkName:"creditCard"*/"../views/mine/CreditCard")
const BindCard = () => import(/*webpackChunkName:"bindCard"*/"../views/mine/BindCard")
const Information = () => import(/*webpackChunkName:"information"*/"../views/mine/Information")
const InfoDetail = () => import(/*webpackChunkName:"infoDetail"*/"../views/mine/InfoDetail")
const SelectShop = () => import(/*webpackChunkName:"selectShop"*/"../views/main/SelectShop")
const ShopDetail = () => import(/*webpackChunkName:"shopDetail"*/"../views/main/ShopDetail")
const OrderFood = () => import(/*webpackChunkName:"orderFood"*/"../views/main/OrderFood")
const FoodDetail = () => import(/*webpackChunkName:"foodDetail"*/"../views/main/FoodDetail")
const ConfirmOrder = () => import(/*webpackChunkName:"confirmOrder"*/"../views/main/ConfirmOrder")
const MyScore = () => import(/*webpackChunkName:"confirmOrder"*/"../views/mine/MyScore")
const OrderDetail = () => import(/*webpackChunkName:"orderDetail"*/"../views/order/OrderDetail")
const RecomendFood = () => import(/*webpackChunkName:"recomendFood"*/"../views/main/RecomendFood")
const CouponDetail = () => import(/*webpackChunkName:"couponDetail"*/"../views/mine/CouponDetail")
const Transfer = () => import(/*webpackChunkName:"transfer"*/"../views/Transfer")
const Demo = () => import(/*webpackChunkName:"demo"*/"../views/Demo")
const GoogeleView = () => import(/*webpackChunkName:"googeleView"*/"../views/mine/GoogeleView")
const Game = () => import(/*webpackChunkName:"googeleView"*/"../views/mine/Game")



const routes = [
  {
    path: '/',
    redirect: "/home",
  },
  {
    path: '/dist/index.html',
    redirect: "/home",
  },
  {
    path: '/index.html',
    redirect: "/home",
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/coupon/:params',
    name: 'Coupon',
    component: Coupon,
  },
  {
    path: '/orderList',
    name: 'OrderList',
    component: OrderList,
  },
  {
    path: '/mine',
    name: 'Mine',
    component: Mine,
  },
  {
    path: '/personInfo',
    name: 'PersonInfo',
    component: PersonInfo,
  },
  {
    path: '/editPas',
    name: 'EditPas',
    component: EditPas,
  },
  {
    path: '/vipcode',
    name: 'VipCode',
    component: VipCode,
  },
  {
    path: '/score/:params',
    name: 'Score',
    component: Score,
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: Agreement,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/place/:params',
    name: 'Place',
    component: Place,
  },
  {
    path: '/sendPlace/',
    name: 'SendPlace',
    component: SendPlace,
  },
  {
    path: '/creditCard/:params',
    name: 'CreditCard',
    component: CreditCard,
  },
  {
    path: '/bindCard',
    name: 'BindCard',
    component: BindCard,
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
  },
  {
    path: '/infoDetail',
    name: 'InfoDetail',
    component: InfoDetail,
  },
  {
    path: '/selectShop',
    name: 'SelectShop',
    component: SelectShop,
  },
  {
    path: '/shopDetail',
    name: 'ShopDetail',
    component: ShopDetail,
  },
  {
    path: '/orderFood/:type',
    name: 'OrderFood',
    meta: {
      first: false
    },
    component: OrderFood
  },
  {
    path: '/foodDetail/:info',
    name: 'FoodDetail',
    component: FoodDetail,
  },
  {
    path: '/confirmorder/:query',
    name: 'ConfirmOrder',
    component: ConfirmOrder,
  },
  {
    path: '/myScore/:params',
    name: 'MyScore',
    component: MyScore,
  },
  {
    path: '/orderDetail/:info',
    name: 'OrderDetail',
    component: OrderDetail,
  },
  {
    path: '/recomendFood',
    name: 'RecomendFood',
    component: RecomendFood,
  },
  {
    path: "/couponDetail",
    name: "CouponDetail",
    component: CouponDetail
  },
  {
    path: "/demo",
    name: "Demo",
    component: Demo
  },
  {
    path: "/transfer",
    name: "Transfer",
    component: Transfer
  },
  {
    path: "/googeleView",
    name: "GoogeleView",
    component: GoogeleView
  },
  {
    path: "/game",
    name: "Game",
    component: Game
  }
]
var options = {
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
}
const router = createRouter(options)
router.beforeEach((to, from, next) => {
  if (["Home","Coupon","OrderList","Mine"].includes(to.name)) {
    store.commit("saveIsShowLoading", false)
  }
  // 进来的页面路径有hash值，重定向
  if (!to.path.hash&&to.path.startsWith('/#')) {
    const newPath = to.path.replace('/#', '');
    next(newPath);
  }
  let pagePath;
  // 获取判断utm参数
  let saveUtmInfo = localStorage.getItem("saveUtmInfo");
  let saveUtmInfoList = saveUtmInfo?saveUtmInfo.split('&'):[];
  let firstJoinData = sessionStorage.getItem("firstJoinData");
  if(firstJoinData&&saveUtmInfoList.length){
    saveUtmInfoList.forEach((item)=>{
      let key = item.split('=')[0]
      let value = item.split('=')[1]
      to.query[key] = value;
    })
    // 用于游客模式拼接utm参数
    if(!store.state.userInfo&&firstJoinData&&!to.query._index){
      to.query._index = 0
      next(to)
      return false;
    }
  }

  console.log(pagePath,'pagePath',saveUtmInfo)
	var userInfo = store.state.userInfo

	if(userInfo){
		if (userInfo.userId) {
			var userId = window.btoa(window.btoa(userInfo.userId))
			if (userId !== to.query._index) {
				to.query._index = userId
				// router.push({
				// 	path: to.path,
				// 	query: to.query
				// })
        next(to)
        // to.query = {
        //   ...(to.query || {}),
        //   _index: userId
        // }
        // next(to)
				return false
			}
		}
	} else {
    delete to.query._index;
    let yuuUrl
    let yuuParam = localStorage.getItem("yuuParam");
    yuuParam = JSON.parse(yuuParam)
    if (yuuParam) {
      if (yuuParam.isYuu) {
        yuuUrl = localStorage.getItem("yuuUrl")
        yuuUrl = yuuUrl.split("?")[1]
      }
    }
    if (yuuUrl) {
      if (yuuParam?.idtoken !== to.query?.idtoken) {
        let Obj = {...to.query,...yuuParam}
        router.push({
					path: to.path,
					query:Obj
				})
        // to.query = Obj
        // next(to)
        next()
        return false
      }
    }
    // else{
    //   // 非yuu游客模式处理
    //   if(firstJoinData&&!to.query._index){
    //     to.query._index = 0
    //     next(to)
    //     return false;
    //   }
    //   to.query._index
    // }
  }
  next()
})
router.beforeResolve((to,from) => {
try{
  // 登录注册页跳到隐私政策时缓存表单所填信息，其他页面清除缓存
  if((from.name!='Privacy'&&from.name!='Agreement'&&to.name=='Register')
    ||(from.name!='Register'&&(to.name=='Privacy'||to.name=='Agreement'))){
      store.commit("setRegisterData",null)
    }
    // 判断链接语言跟带参数语言不一致时，刷新页面
    var search = window.location.search.replace(/\//,'').replace(/\?/,'').split('&');
    // var hashList = window.location.hash.replace(/^.+(?<=\?)/,'').split('&');
    // var hashList = [];
    // var hashVal = window.location.hash.split('?');
    // if(hashVal&&hashVal.length>1){
    //   hashList = hashVal[1].split('&');
    // }
    // /(?:\/)([^#]+)(?=#*)/
    // 链接所带入的参数名带‘utm_’的参数需要保存，跳转到订单详情时需要拼接上，以防止GA请求时丢失数据
    let utm_Data_search = search.filter(m=>/^utm_/.test(m));
    // let utm_Data_hash = hashList.filter(m=>/^utm_/.test(m));
    // let utm_Data = [...search];
    let utm_Data = [...utm_Data_search];
    utm_Data = Array.from(new Set(utm_Data));
    if(utm_Data&&utm_Data.length){
      sessionStorage.setItem("firstJoinData", 1);
      let utm_DataStr = utm_Data.join('&')
      if(!store.state.utmData||store.state.utmData!=utm_DataStr){
        console.log(utm_DataStr,'utm_DataStr')
        store.commit("saveUtmInfo", utm_DataStr);
        localStorage.setItem("saveUtmInfo", utm_DataStr)
      }
    }else{
      let firstJoinData = sessionStorage.getItem("firstJoinData");
      if(!firstJoinData){
        store.commit("saveUtmInfo", '');
        localStorage.setItem("saveUtmInfo", "")
      }

   
    }
 

  var currenLang = ''
  if (search&&search.length) {
     for (var j = 0; j < search.length; j++) {
        if(search[j].indexOf('lang')>-1){
          search[j].indexOf('tc')>-1&&(currenLang = 'tc')
          search[j].indexOf('en')>-1&&(currenLang = 'en')
          break;
        }
     }
  }
  if((to.query&&to.query.lang)||currenLang){
    if(to.query&&to.query.lang){
      currenLang = to.query.lang
    }
    if (currenLang == "tc") {
      if(store.state.language==2){
        store.commit("setLanguage", 1);
        // window.location.reload()
      }
    } else if (currenLang == "en") {
      if(store.state.language==1){
        store.commit("setLanguage", 2);
        // window.location.reload()
      }
    }
  }
  if (to.name != "ConfirmOrder" && to.name != "OrderFood"  && to.name != "Transfer" && to.name != "FoodDetail"&&to.name != 'SelectShop'&&to.name != "SendPlace") {
    var cart = store.state.cart;
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].isCouponPro) {
        var productObj = {};
        var product = cart[i];
        product.num = 0;
        productObj.product = product;
        productObj.cart = true;
        store.commit("createCart", productObj);
        break;
      }
    }
	store.commit("changeClearCou", true);
	store.commit("saveCouponInfo", null);
	store.commit("saveIsReturnMap", true);
	store.commit("clearCouponInfo", null);
  
	sessionStorage.setItem("firstJoinCoupon", 0);
	localStorage.removeItem("ShowCouponErrConfirm")
  }
  localStorage.setItem("store", JSON.stringify(store.state))

}catch(err){
  console.log(err);
}
})

export default router
