<template>
  <div class="setPage">
    <div class="box">
      <i class="logo"></i>
      <p class="title">{{ title }}</p>
      <p class="content">{{ content }}</p>
      <div class="btn">
        <p @click="leftEvent">{{ leftBtn }}</p>
        <p @click="rightEvent">{{ rightBtn }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    leftBtn: {
      type: String,
      default: "",
    },
    rightBtn: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  methods: {
    leftEvent() {
      this.$emit("leftEvent");
    },
    rightEvent() {
      this.$emit("rightEvent");
    },
  },
};
</script>
<style scoped>
.setPage {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999999999999999999;
}
.box {
  width: 2.7rem;
  background-color: #fff;
  backdrop-filter: blur(54.3656px);
  border-radius: 0.14rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.title {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-align: center;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 0.19rem;
  margin-bottom: 0.07rem;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.content {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 0.13rem;
  line-height: 0.16rem;
  text-align: center;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #808080;
  margin-top: 0.19rem;
  border-top: 0.01rem solid rgba(60, 60, 67, 0.36);
  height: 0.44rem;
}
.btn p {
  width: 49.5%;
  height: 100%;
  line-height: 0.44rem;
}
.btn p + p {
  color: #e4022b;
  border-left: 0.01rem solid rgba(60, 60, 67, 0.36);
}

.logo {
  /* width: 1.56rem; */
  display: block;
  background: url("../../assets/icon/mainT.png") no-repeat center;
  background-size: 1.56rem 0.1rem;
  height: 0.1rem;
  margin-bottom: 0.2rem;
}
</style>