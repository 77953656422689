<template>
  <div v-show="!hidden" class="icon" v-bind="attr">
    <img :src="imgSrc" alt="">
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "icon",
  props: {
    primary: { // 主颜色 背景为红色， 默认为白色
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const imgSource = {
      addr: require("../../assets/icon/icon-addr.png"),
      addw: require("../../assets/icon/icon-addw.png"),
      delr: require("../../assets/icon/icon-delr.png"),
      delw: require("../../assets/icon/icon-delw.png"),
    };

    const attr = computed({
      get() {
        return {
          type: props.type,
          primary: props.primary,
          disabled: props.disabled
        }
      },
    })
    const imgSrc = computed({
      get() {
        let {type, primary, disabled} = props
        if (disabled) return imgSource[type + 'r']
        return imgSource[type + (primary ? 'w' : 'r')]
      },
    })

    return {
      imgSrc,
      attr
    }
  }
}
</script>

<style lang="scss" scoped>

.icon {
  height: 0.24rem;
  width: 0.24rem;
  border: 1px solid #e3002b;
  border-radius: 0.04rem;
  background-color: white;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &[primary = true] {
    background-color: #e3002b;
  }

  &[disabled = true] {
    background-color: #bbb3b3;
    border-color: #bbb3b3;
  }

  &[type='del'] {
    img {
      width: 0.13rem;
      height: auto;
    }
  }

  &[type='add'] {
    img {
      width: 0.1rem;
      height: 0.1rem;
    }
  }
}

</style>
