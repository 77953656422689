<template>
  <div class="confirm">
    <div class="content">
      <!-- <span class="closeIcon" @click="closeConfirm"></span> -->
      <img src="@/assets/icon/mainT.png" alt="" />
      <p class="title" :style="{ fontSize: size + 'rem' }" v-html="title"></p>
      <p
        v-if="subTitle"
        v-html="subTitle"
        class="title"
        :style="{ fontSize: size + 'rem' }"
      ></p>
      <p
        v-if="subTitleE"
        v-html="subTitleE"
        class="title"
        :style="{ fontSize: size + 'rem' }"
      ></p>
      <p
        v-if="subTitleF"
        v-html="subTitleF"
        class="title"
        :style="{ fontSize: size + 'rem' }"
      ></p>
      <p v-if="info" class="info" :style="style" v-html="info"></p>
      <div
        v-if="type == 1"
        class="btnBox active"
        :class="{ scaleActive: canScale }"
      >
        <span @click="rightCoutine">{{ rightBtn }}</span>
      </div>
      <div v-else class="btnBox" :class="{ scaleActive: canScale }">
        <span @click="cancle">{{ leftBtn }}</span>
        <i></i>
        <span @click="rightCoutine">{{ rightBtn }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "FoodConfirm",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      default: false,
    },
    subTitleE: {
      default: false,
    },
    subTitleF: {
      default: false,
    },
    size: {
      default: 0.15,
    },
    info: {
      type: String,
      default: "",
    },
    style: {
      type: String,
      default: "",
    },
    leftBtn: {
      type: String,
      default: "取消",
    },
    rightBtn: {
      type: String,
      default: "繼續點單",
    },
    type: {
      default: 2,
    },
  },
  methods: {
    rightCoutine() {
      this.$emit("cancle");
    },
    cancle() {
      this.$emit("confirm");
      // if (this.leftBtn == "取消") {
      //   this.$router.back(-1);
      // }
    },
  },
  computed: {
    ...mapState(["isPC"]),
    canScale() {
      if (this.isPC && this.$store.state.language != 1) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.confirm {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
.content {
  width: 3rem;
  background-color: #fff;
  border-radius: 0.14rem;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 0.16rem; */
  box-sizing: border-box;
}
.content img {
  /* width: 1.56rem; */
  height: 0.1rem;
  object-fit: cover;
  margin-bottom: 0.2rem;
}
.title {
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.22rem;
  color: #03060d;
  /* margin-bottom: 0.16rem; */
  padding: 0 0.01rem;
}
.info {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #808080;
  /* color: #474747; */
  /* margin-bottom: 0.25rem; */
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.info p + p {
  margin-top: 0.1rem;
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.01rem solid #cacbcc;
  height: 0.54rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.54rem;
  color: #808080;
  margin-top: .16rem;
}
.btnBox i {
  height: 100%;
  border-left: 0.01rem solid #cacbcc;
}
.btnBox span {
  width: 50%;
  text-align: center;
  line-height: 0.22rem;
}
.btnBox span:last-child {
  color: #e4022b;
}
.btnBox.active span {
  width: 100%;
}
.closeIcon {
  width: 0.13rem;
  height: 0.13rem;
  background: url("../../assets/icon/close.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0.23rem;
  right: 0.17rem;
}
.btnBox.scaleActive span {
  white-space: nowrap !important;
  transform: scale(0.9) !important;
}
</style>